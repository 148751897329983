import React from 'react';

interface ColorOverlayFilterProps {
  color: string;
  id: string;
  opacity?: number;
}

const ColorOverlayFilter: React.FC<ColorOverlayFilterProps> = ({
  color,
  id,
  opacity,
}) => {
  const [r, setR] = React.useState(0);
  const [g, setG] = React.useState(0);
  const [b, setB] = React.useState(0);

  React.useEffect(() => {
    let c: string[] = [];

    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
      c = color.substring(1).split('');

      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      const rHex: string = c.slice(0, 2).join('');
      const gHex: string = c.slice(2, 4).join('');
      const bHex: string = c.slice(4, 6).join('');
      setR(parseInt(rHex, 16) / 255);
      setG(parseInt(gHex, 16) / 255);
      setB(parseInt(bHex, 16) / 255);
    }
  });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="svg-filter">
      <filter id={id}>
        <feColorMatrix
          type="matrix"
          result="white"
          values={`0 0 0 0 ${r}
                0 0 0 0 ${g}
                0 0 0 0 ${b}
                0 0 0 ${opacity || 1} 0`}
        />
      </filter>
    </svg>
  );
};

export default ColorOverlayFilter;
