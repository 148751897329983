import React from 'react';

interface DuotoneFilterProps {
  r: string;
  g: string;
  b: string;
  a?: string;
  id: string;
}

const DuotoneFilter: React.FC<DuotoneFilterProps> = ({ r, g, b, a, id }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="svg-filter">
    <filter id={id}>
      <feColorMatrix
        type="matrix"
        result="grayscale"
        values="1 0 0 0 0
                1 0 0 0 0
                1 0 0 0 0
                0 0 0 1 0"
      />
      <feComponentTransfer colorInterpolationFilters="sRGB" result="duotone">
        <feFuncR type="table" tableValues={r} />
        <feFuncG type="table" tableValues={g} />
        <feFuncB type="table" tableValues={b} />
        <feFuncA type="table" tableValues={!a ? '0 1' : a} />
      </feComponentTransfer>
    </filter>
  </svg>
);

export default DuotoneFilter;
